.contact-form-wrapper {
  margin-top: 50px;
}

.h2 {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.1rem;
  font-family: "Montserrat", sans-serif;
}

.success {
  font-size: 1.2rem;
  color: green;
}

.hidden {
  display: none;
}
