.banner-container {
  width: 100%;
  // height: 1000px;
  display: flex;
  flex-flow: column nowrap;
  font-family: "Josefin Sans", sans-serif;
  min-height: 101vh;

  .banner-img-wrapper {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    max-height: 500px;
    height: 100%;
    // flex-shrink: 0;
    // position: relative;
    .banner-header-img {
      // display: block;
      // position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-width: 700px;
      height: 101vh;
    }
  }
  .banner-story {
    padding: 10px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    .banner-header {
      .my-img {
        height: 250px;
        // width: 100px;
        border-radius: 100%;
      }
      h1 {
        margin-top: 15px;
        font-weight: 100;
        letter-spacing: -0.3rem;
        font-size: 3.5rem;
      }
    }
    .banner-subheader {
      padding: 15px 0 15px 0;
      p {
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.35rem;
      letter-spacing: -0.1rem;
    }
    // .banner-btn {
    //   display: flex;
    //   justify-content: center;
    //   a {
    //     font-size: 1.2rem;
    //     font-weight: 400;
    //     line-height: 4em;
    //     height: 4em;
    //     min-width: 14em;
    //     border-radius: 4em;
    //     text-decoration: none;
    //     color: black;
    //     margin-right: 5%;
    //     box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
    //     transition: background-color 0.2s ease-in-out,
    //       box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
    //     &:hover {
    //       color: #47d3e5;
    //       box-shadow: inset 0 0 0 1px #47d3e5;
    //     }
    //   }
    // }
  }
  .desktop {
    display: none;
  }
}

@media (min-width: 700px) {
  .banner-container {
    .banner-story {
      .banner-header {
        h1 {
          font-size: 3.8rem;
        }
      }
      .banner-subheader {
        p {
          font-size: 1.8rem;
        }
      }
      .banner-detail {
        font-size: 1.5rem;
      }
    }
  }
}

@media (min-width: 1000px) {
  .mobile {
    display: none;
  }
  .banner-container {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    .desktop {
      display: flex;
    }
    .banner-img-wrapper {
      // display: block;
      order: 2;
      width: 70%;
      max-height: 100%;
      height: 101vh;
      .banner-header-img {
        vertical-align: top;
        height: 101vh;
        flex-shrink: 0;
        position: relative;
      }
    }
    .banner-story {
      width: 45%;
      margin: auto;
      .banner-header {
        h1 {
          font-size: 4rem;
        }
      }
      .banner-subheader {
        p {
          font-size: 1.95rem;
        }
      }
      .banner-detail {
        font-size: 1.55rem;
      }
    }
  }
}

@media (min-width: 1300px) {
  .mobile {
  }
  .banner-container {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    .desktop {
      display: flex;
    }
    .banner-img-wrapper {
      // display: block;
      order: 2;
      width: 70%;
      max-height: 100%;
      height: 101vh;
      .parralax-inner {
        width: 100%;
      }
      .banner-header-img {
        vertical-align: top;
        height: 101vh;
        flex-shrink: 0;
        position: relative;
        // min-width: 100%;
      }
    }
    .banner-story {
      width: 45%;
      margin: auto;
      .banner-header {
        h1 {
          font-size: 4.5rem;
        }
      }
      .banner-subheader {
        p {
          font-size: 2.2rem;
        }
      }
      .banner-detail {
        font-size: 1.75rem;
      }
    }
  }
}
