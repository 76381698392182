.projects-list-wrapper {
  width: 100%;
  .project-item {
    width: 100%;
    font-family: "Josefin Sans", sans-serif;
    cursor: pointer;
    .project-img-wrapper {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      height: 295px;
      .project-img {
        width: 100%;
        height: 100%;
        min-width: 700px;
        min-height: 300px;
      }
    }
    .project-text {
      padding: 20px 30px 20px 30px;
      .project-title {
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: -0.1rem;
      }
      .project-description {
        font-size: 1.4rem;
        // letter-spacing: 0.1rem;
        margin-bottom: 30px;
      }
      .project-actions {
        display: flex;
        justify-content: center;
        .deployment-btn {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 4em;
          height: 4em;
          min-width: 14em;
          border-radius: 4em;
          text-decoration: none;
          color: black;
          margin-right: 5%;
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
          transition: background-color 0.2s ease-in-out,
            box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
          &:hover {
            color: #47d3e5;
            box-shadow: inset 0 0 0 1px #47d3e5;
          }
        }
        img {
          margin-top: 1em;
        }
      }
    }
  }
}
.modal-content {
  font-family: "Josefin Sans", sans-serif;
  .modal-header {
    border-bottom: none;
    h4 {
      font-size: 2.5rem;
    }
  }
  .modal-body {
    .project-img {
      width: 100%;
    }
    p {
      margin-top: 20px;
      font-size: 1.4rem;
      font-family: "Josefin Sans", sans-serif;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;
  }
}

@media (min-width: 700px) {
  .projects-list-wrapper {
    .project-item {
      display: flex;
      justify-content: space-between;
      .project-img-wrapper {
        //   display: inline-block;
        overflow: hidden;
        //   position: relative;
        width: 40%;
        height: 100%;
        flex-shrink: 0;
        .project-img {
          // position: absolute;
          top: 0;
          left: 0;
          min-width: 550px;
          height: 100%;
        }
      }
      .right {
        order: 1;
      }
      .project-text {
        margin: auto;
        width: 49%;
      }
    }
  }
}

@media (min-width: 1000px) {
  .projects-list-wrapper {
    .project-item {
      // .project-img-wrapper {
      // }
      .project-text {
        .project-title {
          font-size: 3rem;
        }
        .project-description {
          font-size: 1.6rem;
        }
      }
    }
  }
  .modal-content {
    .modal-header {
      h4 {
        font-size: 2.5rem;
      }
    }
    .modal-body {
      .project-img {
        width: 100%;
      }
      p {
        margin-top: 20px;
        font-size: 1.6rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .projects-list-wrapper {
    .project-item {
      height: 450px;
      // display: flex;
      // justify-content: space-between;
      .project-img-wrapper {
        overflow: hidden;
        width: 52%;
        height: 100%;
        flex-shrink: 0;
        .project-img {
          // position: absolute;
          top: 0;
          left: 0;
          min-width: 550px;
          height: 100%;
        }
      }
      .right {
        order: 1;
      }
      .project-text {
        margin: auto;
        width: 49%;
      }
    }
  }
}

@media (min-width: 1600px) {
  .projects-list-wrapper {
    .project-item {
      height: 450px;
      // display: flex;
      // justify-content: space-between;
      .project-img-wrapper {
        overflow: hidden;
        width: 52%;
        height: 100%;
        flex-shrink: 0;
        .project-img {
          // position: absolute;
          top: 0;
          left: 0;
          min-width: 550px;
          height: 100%;
        }
      }
      .right {
        order: 1;
      }
      .project-text {
        .project-title {
          font-size: 3.5rem;
        }
        .project-description {
          font-size: 2rem;
        }
      }
    }
  }
}
