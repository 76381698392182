body {
  margin: 0;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.65;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 62.5%;
}
